const initialState = {
    sidebarShow:'responsive'
};
 
export const ThemeReducers = (state = initialState, action)=> {
    switch (action.type){        
        case 'set':
        return {
            ...state,sidebarShow:action.payload
        }            
        default:
            return state;
    }
}