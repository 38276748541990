import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { Provider } from "react-redux";
import {store} from './Redux/store';
import { ToastContainer} from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/" render={props => <TheLayout {...props}/>} />
            </Switch>
            <ToastContainer position="top-right" />
          </React.Suspense>
        </Router>
      </Provider>
    );
  }
}

export default App;
