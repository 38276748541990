import {ADMINLOGIN,ADMINLOGOUT} from './actionTypes';

const initialState = {
    adminLogin:{
        
    },
    loadedAt:0
};
 
export const AdminAuthReducer = (state = initialState, action)=> {
    switch (action.type){
        case ADMINLOGIN:
            return {
                ...state,adminLogin:action.payload
            }
        case ADMINLOGOUT:
        return {
            ...state,adminLogin:{}
        }       
        case 'loadedAt':
        return {
            ...state,loadedAt:action.payload
        }            
        default:
            return state;
    }
}